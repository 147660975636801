@use "./variables" as *;

/* Containers ----------------------- */

@mixin container {
    max-width: 992px;
    margin: 0 auto;
}

/* Responsive breakpoints ----------- */

@mixin xxl {
    @media (max-width: 1919.98px) { 
        @content; 
    }
}

@mixin xl {
    @media (max-width: 1399.98px) { 
        @content; 
    }
}

@mixin lg {
    @media (max-width: 1199.98px) {
        @content; 
    }
}

@mixin md {
    @media (max-width: 991.98px) {
        @content; 
    }
}

@mixin sm {
    @media (max-width: 767.98px) {
        @content;
    }
}

@mixin xs {
    @media (max-width: 575.98px) {
        @content;
    }
}

/* Headings ------------------------- */

@mixin heading-xl {
    font-size: $fs-2-xl;
    font-weight: $fw-semi-bold;
    line-height: 1.2;
}

@mixin heading-lg {
    font-size: $fs-xl;
    font-weight: $fw-medium;
    line-height: 1.3;
}

@mixin heading-md {
    font-size: $fs-lg;
    font-weight: $fw-medium;
    line-height: 1.4;  
}

@mixin heading-sm {
    font-size: $fs-md;
    font-weight: $fw-regular;
    line-height: 1.5;
}

@mixin heading-xs {
    font-size: $fs-sm;
    font-weight: $fw-regular;
    line-height: 1.6;  
}

@mixin heading-xxs {
    font-size: $fs-xs;
    font-weight: $fw-regular;
    line-height: 1.7; 
}