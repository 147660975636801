/* Color palette ----------------------- */

$accent: #235EE7;
$background: #F6F8FA;
$surface: #FFFFFF;
$error: #FF403B;

/* Font colors ----------------------- */

$on-accent: #FFFFFF;
$on-background: #06080D;
$on-surface: #06080D;
$on-error: #FFFFFF;

/* Font sizes ------------------------ */

$fs-6-xl: 6.4rem;
$fs-5-xl: 5.6rem;
$fs-4-xl: 4.8rem;
$fs-3-xl: 4rem;
$fs-2-xl: 3.2rem;
$fs-xl: 2.4rem;
$fs-lg: 1.8rem;
$fs-md: 1.6rem;
$fs-sm: 1.4rem;
$fs-xs: 1.2rem;

/* Font weights ---------------------- */
 
$fw-black: 900;
$fw-extra-bold: 800;
$fw-bold: 700;
$fw-semi-bold: 600;
$fw-medium: 500;
$fw-regular: 400;

/* Emphasis (opacity) --------------- */
 
$emph-base: 1;
$emph-high: .87;
$emph-md: .6;
$emph-low: .38;

/* Spacing -------------------------- */

$space-6-xl: 256px;
$space-5-xl: 128px;
$space-4-xl: 96px;
$space-3-xl: 64px;
$space-2-xl: 48px;
$space-xl: 32px;
$space-lg: 24px;
$space-md: 16px;
$space-sm: 12px;
$space-xs: 8px;
$space-2-xs: 4px;
$space-3-xs: 2px;
