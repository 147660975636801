@use "../../assets/styles/variables" as *;
@use "../../assets/styles/mixins" as *;

.info-piece {
    display: flex;
    flex-direction: column;
    gap: $space-xs;

    &__heading {
        opacity: $emph-md;

        @include heading-sm;
    }
}