@use "./variables" as *;

/* Fonts ---------------------------- */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

/* Base styling & resets ------------ */

*, 
*:before, 
*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    text-decoration: none;
    outline: none;
}

html {
    color: $on-surface;
    font-family: "Inter", sans-serif;
    font-size: 62.5%; // 1rem = 10px
    font-weight: $fw-regular;
    scroll-behavior: smooth;
}

body { background: $background; }

img,
svg { 
    display: block;
    max-width: 100%; 
    height: auto;
}

button {
    font-family: inherit;
    background: transparent; 
    cursor: pointer;
}