@use "./variables" as *;
@use "./mixins" as *;

h1 { @include heading-xl; }

h2 { @include heading-lg; }

h3 { @include heading-md; }

h4 { @include heading-sm; }

h5 { @include heading-xs; }

h6 { @include heading-xxs; }

p {
    font-size: $fs-md;
    line-height: 1.5;

    a { 
        color: $accent;
        text-decoration: underline;
    }
}

strong { font-weight: $fw-medium; }
  
ul,
ol {
    display: flex;
    flex-direction: column;
    gap: $space-xs;
    padding-left: 20px;
    font-size: $fs-md;
    line-height: 1.5;
}

::selection {
    color: $on-accent;
    background: $accent;
}