@use "./assets/styles/variables" as *;
@use "./assets/styles/mixins" as *;

.main-content-wrapper {
    padding: $space-4-xl $space-md;
    background: $background;

    @include sm { padding: $space-2-xl $space-md; }
}

.main-content {
    display: flex;
    flex-direction: column;
    gap: $space-2-xl;
    
    @include container;
}

.columns {
    display: flex;
    gap: $space-lg;

    @include xs { 
        flex-direction: column; 
        gap: $space-2-xl;
    }

    &__left { flex: 1; }

    &__right { flex: 1; }
}

