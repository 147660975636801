@use "../../assets/styles/variables" as *;
@use "../../assets/styles/mixins" as *;

.info-card {
    display: flex;
    flex-direction: column;
    gap: $space-lg;

    &__heading {
        @include heading-md;
    }

    &__body {
        display: flex;
        flex-direction: column;
        gap: $space-xl;
        padding: $space-lg;
        background: $surface;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba($color: #000000, $alpha: .05);
    }
}