@use "../../assets/styles/variables" as *;
@use "../../assets/styles/mixins" as *;

.download-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $space-lg;
    border-radius: 10px;
    color: $on-surface;
    text-decoration: none;

    &__btn {
        display: flex;
        align-items: center;
        gap: $space-xs;
        color: $accent;

        svg { min-width: 18px; }
    }
}